function SvgLock({ fill = "#614f4133" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4339_8240)">
        <path
          d="M14.1668 6.66683V5.00016C14.1668 2.70016 12.3002 0.833496 10.0002 0.833496C7.70016 0.833496 5.8335 2.70016 5.8335 5.00016V6.66683H3.3335V18.3335H16.6668V6.66683H14.1668ZM7.50016 5.00016C7.50016 3.61683 8.61683 2.50016 10.0002 2.50016C11.3835 2.50016 12.5002 3.61683 12.5002 5.00016V6.66683H7.50016V5.00016ZM15.0002 16.6668H5.00016V8.3335H15.0002V16.6668ZM10.0002 14.1668C10.9168 14.1668 11.6668 13.4168 11.6668 12.5002C11.6668 11.5835 10.9168 10.8335 10.0002 10.8335C9.0835 10.8335 8.3335 11.5835 8.3335 12.5002C8.3335 13.4168 9.0835 14.1668 10.0002 14.1668Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4339_8240">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLock;
