export function AvatarLG(props) {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="48" cy="48" r="48" fill="#0c3e83" />
      <circle cx="47.25" cy="30.75" r="17.25" fill="white" />
      <ellipse cx="48" cy="69" rx="28.5" ry="15" fill="white" />
      <path
        d="M47.25 13.5C42.675 13.5 38.2874 15.3174 35.0524 18.5524C31.8174 21.7874 30 26.175 30 30.75C30 35.325 31.8174 39.7126 35.0524 42.9476C38.2874 46.1826 42.675 48 47.25 48L47.25 30.75V13.5Z"
        fill="#CFE4F1"
      />
      <path
        d="M48 84C40.4413 84 33.1922 82.4196 27.8475 79.6066C22.5027 76.7936 19.5 72.9782 19.5 69C19.5 65.0218 22.5027 61.2064 27.8475 58.3934C33.1922 55.5804 40.4413 54 48 54V69L48 84Z"
        fill="#CFE4F1"
      />
    </svg>
  );
}
export function AvatarSM(props) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#0c3e83" />
      <circle cx="23.625" cy="15.375" r="8.625" fill="white" />
      <ellipse cx="24" cy="34.5" rx="14.25" ry="7.5" fill="white" />
      <path
        d="M23.625 6.75C21.3375 6.75 19.1437 7.6587 17.5262 9.2762C15.9087 10.8937 15 13.0875 15 15.375C15 17.6625 15.9087 19.8563 17.5262 21.4738C19.1437 23.0913 21.3375 24 23.625 24L23.625 15.375V6.75Z"
        fill="#CFE4F1"
      />
      <path
        d="M24 42C20.2207 42 16.5961 41.2098 13.9237 39.8033C11.2513 38.3968 9.75 36.4891 9.75 34.5C9.75 32.5109 11.2513 30.6032 13.9237 29.1967C16.5961 27.7902 20.2207 27 24 27V34.5L24 42Z"
        fill="#CFE4F1"
      />
    </svg>
  );
}
